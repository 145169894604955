@font-face {
  font-family: 'SF Compact Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Medium'),
    url('./../../fonts/SF-Compact-Display-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SF Compact Display Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Regular'),
    url('./../../fonts/SF-Compact-Display-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'SF Compact Display Bold';
  font-style: normal;
  font-weight: bold;
  src: local('SF Compact Display Bold'),
    url('./../../fonts/SF-Compact-Display-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'SF Compact Display Black';
  font-style: bold;
  font-weight: bold;
  src: local('SF Compact Display Black'),
    url('./../../fonts/SF-Compact-Display-Black.otf') format('opentype');
}
